const handleFirstTab = (e) => {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing')

    window.removeEventListener('keydown', handleFirstTab)
    window.addEventListener('mousedown', handleMouseDownOnce)
  }
}

const handleMouseDownOnce = () => {
  document.body.classList.remove('user-is-tabbing')

  window.removeEventListener('mousedown', handleMouseDownOnce)
  window.addEventListener('keydown', handleFirstTab)
}

window.addEventListener('keydown', handleFirstTab)

const updateTitleIframes = () => {
  setTimeout(() => {
    const iframePixels = document.querySelector('#WebPixelsManagerSandboxContainer iframe')
    const iframeWebMessenger = document.querySelector('#web-messenger-container')
    const joinAdInterestGroup = document.querySelector('iframe[allow="join-ad-interest-group"]')
    const iframeBlank = document.querySelector('iframe[src="about:blank"]')
    if (iframeBlank) {
      iframeBlank.setAttribute('role', 'none')
      iframeBlank.setAttribute('aria-hidden', 'true')
    }

    if (iframePixels) {
      iframePixels.setAttribute('title', 'WebPixels Manager Sandbox')
    }
    if (iframeWebMessenger) {
      iframeWebMessenger.setAttribute('title', 'Web Messenger')
    }
    if(joinAdInterestGroup) {
      joinAdInterestGroup.setAttribute('title', 'Join ad Interest Group')
    }

    const widthIframeWebMessenger = (78 / window.innerWidth) * 100
    const heightIframeWebMessenger = (96 / window.innerHeight) * 100
    const iframeWebMessengerEls = document.querySelectorAll('iframe#web-messenger-container')

    if (iframeWebMessengerEls.length) {
      iframeWebMessengerEls.forEach(item => {
        item.width = widthIframeWebMessenger + '%'
        item.height = heightIframeWebMessenger + '%'
      })
    }

  }, 5000)
}

setTimeout(() => {
  if (window._klOnsite) {
    const closeKlaviyo = document.querySelector('.klaviyo-close-form svg')
    const searchCountryEl = document.querySelector('[aria-label="Search Countries"]')
    const searchCountryImgEl = searchCountryEl?.querySelector('img')
    closeKlaviyo?.removeAttribute('role')
    searchCountryEl?.removeAttribute('role')
    if (searchCountryImgEl) {
      const inputEl = searchCountryEl.nextSibling
      const inputId = inputEl.getAttribute('id')
      const htmlText = `<label for='${inputId}' class="sr-only">Phone Number</label>`
      inputEl.insertAdjacentHTML('beforebegin', htmlText)
      const labelText = searchCountryImgEl.title
      searchCountryImgEl.setAttribute('alt', labelText)
    }
  }
}, 3000)

updateTitleIframes()

const updateIframes = () => {
  const iframes = document.querySelectorAll('iframe')
  if (iframes) {
    iframes.forEach(iframe => {
      iframe.removeAttribute('frameborder')
      iframe.removeAttribute('style')
      iframe.removeAttribute('bgcolor')
      iframe.removeAttribute('align')
    })
  }
}

setTimeout(updateIframes, 2000)


export const updateWarningMessageOpenNewTab = () => {
  const TEXT = 'Opens a new window'
  const selectAllTagA = document.querySelectorAll('a')
  if (selectAllTagA && selectAllTagA.length > 0) {
    selectAllTagA.forEach(item => {
      if (!item.getAttribute('aria-label') && item.target === '_blank') {
        if (!item.innerText.toLowerCase().includes(TEXT.toLowerCase())) {
          const element = document.createElement('span')
          element.classList.add('sr-only')
          element.innerText = TEXT
          item.appendChild(element)
        }
      }
    })
  }
}
